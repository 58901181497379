@import "ember-side-menu";

html, body{
    min-height: 100vh;
    box-sizing: border-box;
}

a{
    color:"gray";
}

a:active{
    color: "black";
}

#body{
    overflow-y: auto;
    padding-top: 50px;
    padding-bottom: 60px;
}


#chameleon-icon img{
    max-height: 30px;
    min-height: 15px;
    width: auto
}

#footer-container-large{
    position: fixed;
    right:0;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    font-size: .6rem;
    background-color:#0CB343;
    color: white;
}


#title-container-small{
    background-color: #0CB343;
    color: white;
    height: 50px;
    padding-right: 25px;
}

#index-header{
    background-color:#0CB343;
    color: white;
    margin: 0 0 10px 0;
    max-height: 7rem;
}

#company-name {
    font-family: 'Rochester', cursive;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    padding-top: 5px;
}

#footer-header{
    color: white;
}

#company-info{
    position:fixed;
    bottom:10px;
}

#company-info-header{
    margin-bottom: 0, auto;
    padding: 2, 2, 2, 2;
}

#company-info-left{
    margin-top: 0, auto;
    bottom: 0px;
}
#company-info-right{
    margin-top: 0, auto;
    bottom: 0px;
}
